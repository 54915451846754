export default function loadScripts() {
  const scriptSources = [
    "/assets/js/jquery-3.6.0.min.js",
    "/assets/js/swiper.min.js",
    "/assets/js/gsap.min.js",
    "/assets/js/ScrollTrigger.min.js",
    "/assets/js/ScrollSmoother.min.js",
    "/assets/js/ui.js",
  ];

  // jQuery가 로드된 후에 실행되는 함수
  const loadUiScript = () => {
    const uiScript = document.createElement("script");
    uiScript.src = "/assets/js/ui.js";
    uiScript.async = true;
    document.body.appendChild(uiScript);
  };

  scriptSources.forEach((src) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    // jQuery가 로드된 후에만 ui.js가 실행되도록 조건 추가
    if (src.includes("jquery")) {
      script.onload = () => {
        console.log("jQuery loaded");
        loadUiScript(); // jQuery가 로드된 후 ui.js를 실행
      };
    }

    document.body.appendChild(script);
  });
}
