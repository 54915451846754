<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DownloadSection from "@/components/DownloadSection.vue";
import { onMounted, ref } from "vue";
import loadScripts from "@/utils/loadScripts.js";
import FixedDownloadMoveButton from "@/components/FixedDownloadMoveButton.vue";

onMounted(() => {
  loadScripts();

  const initSwiperAndGsap = () => {
    const mySwiper = new Swiper(".swiper-riasans", {
      slidesPerView: 2.5,
      spaceBetween: 30,
      initialSlide: 0,
      breakpoints: {
        768: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
      },
    });

    if (mySwiper) {
      mySwiper.update();
    }

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const smoother = ScrollSmoother.create({
      wrapper: ".scroll__wrap",
      content: ".scroll__inner",
      smooth: 1,
      effects: true,
      smoothTouch: 0.1,
    });
    smoother.paused(false);

    const progressDiv = $(".progress__inner");

    ScrollTrigger.create({
      onUpdate({ progress }) {
        progressDiv.css("width", `${(progress * 100).toFixed()}%`);
      },
    });
  };

  // 1초 지연 후 초기화 (스크립트 로드 시간 대기)
  setTimeout(() => {
    if (typeof Swiper !== "undefined" && typeof gsap !== "undefined") {
      initSwiperAndGsap();
    } else {
      console.error("Swiper or GSAP failed to load.");
    }
  }, 1000);
});

const downloadSectionRef = ref(null);

const scrollToDownload = () => {
  if (downloadSectionRef.value && downloadSectionRef.value.$el) {
    const offsetTop =
      downloadSectionRef.value.$el.getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  }
};
</script>

<template>
  <div id="wrap">
    <!-- s:header -->
    <HeaderComponent />
    <!-- //e:header -->
    <FixedDownloadMoveButton @scrollToDownload="scrollToDownload" />

    <!-- s:scroll__wrap -->
    <div class="scroll__wrap">
      <!-- s:scroll__inner -->
      <div class="scroll__inner">
        <!-- SpecialIssueSection -->
        <section class="layout__wrap issue">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Special Issue</p>
              <p class="title">
                “패티의 고정관념을 깨고, <br class="mo-only" />번의 한계를 넘어
                세계로” <br />
                롯데리아 K-버거의 <br class="mo-only" />글로벌 연대기를 쓸 한글
                폰트 <br class="mo-only" />
                리아체 출시!
              </p>
            </div>
            <div class="desc-wrap flex">
              <div class="btn-wrap"></div>
              <p class="desc small">
                본 콘텐츠는 K-버거의 글로벌 역사를 쓰기 위해 개발된 폰트,
                리아체로 제작되었습니다.
              </p>
            </div>
            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_01.png"
                  alt="리아산스 이미지 1"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_01_m.png"
                  alt="리아산스 이미지 1"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End SpecialIssueSection -->

        <!-- IntroSection -->
        <section class="layout__wrap intro">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Intro</p>
              <p class="title">
                햄버거로부터의 탈주, <br class="mo-only" />
                K-버거라는 <br />
                새 장르의 탄생을 <br class="mo-only" />
                알리는 리아체
              </p>
            </div>
            <div class="desc-wrap">
              <p class="desc">
                햄버거의 기원에 대해서라면 할 이야기가 많지만 지금 우리가
                공유하고 있는 일반적인 정의는 ‘쇠고기 다짐육으로 만든 패티를
                구운 후 다양한 부재료와 함께 빵(번) 사이에 끼워 먹는 음식’
                정도라 할 수 있다. 그렇다면 K-버거란 무엇일까? K-버거는 말
                그대로 미국이 아닌 한국식 햄버거를 뜻한다.
                <br /><br />
                하지만 롯데리아가 정의하는 K-버거는 단순히 한국인 입맛에 조금 더
                잘 맞게 개량된 햄버거를 의미하진 않는다. ‘기존 햄버거의
                공식으로부터 탈피한 자유로운 발상과 과감한 행동력으로 햄버거에
                대한 개념을 바꿔버리는 신개념 K-푸드.’ 이것이 바로 K-버거의
                선구자이자 새 역사를 개척하고 있는 롯데리아가 말하는 K-버거의
                정의다. <br />
                <br class="mo-only" />
                그리고 이 창의적인 버거의 존재를 알리기 위해 롯데리아는 2024년
                한글 폰트, 리아체를 개발했다. K-버거의 맛은 물론 그에 어린
                롯데리아의 '토종 버거 정신'을 가장 잘 전달할 수 있는
                커뮤니케이션 도구는 다름 아닌 바로 '한글 폰트'라 판단했기
                때문이다.
              </p>
            </div>

            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_02.png"
                  alt="리아산스 이미지 2"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_02_m.png"
                  alt="리아산스 이미지 2"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End IntroSection -->

        <!-- CampaignSection -->
        <section class="layout__wrap campaign">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Campaign</p>
              <p class="title">
                리아체의 상상은 반드시 <br class="mo-only" />
                리액션을 만든다! <br />
                롯데리아와 K-버거의 원대한 <br class="mo-only" />
                꿈을 현실로 만드는 <br />
                'RIAction! 리액션 캠페인'
              </p>
            </div>
            <div class="desc-wrap">
              <p class="desc">
                롯데리아에게 한글 폰트는 단순한 디자인 요소가 아니다. 거침없는
                개척정신과 굴하지 않는 도전정신으로 대표되는 롯데리아 스피릿을
                전파하기 위한 최적의 커뮤니케이션 도구다. 롯데GRS 디자인 센터는
                K-버거의 세계화라는 롯데리아의 오랜 꿈을 현실로 만드는
                시작점에서, K-버거는 물론 그에 담긴 다채로운 문화를 가장 잘 알릴
                수 있는 한글 폰트 리아체를 기획-제작했다.
                <br /><br />
                촵딱체에 이어 리아체를 함께 만든 롯데GRS 디자인 센터와
                타이포그래피 퍼포먼스 그룹 엉뚱상상(tt.서울 소속)은 롯데리아와
                K-버거의 꿈을 현실로 만들기 위한 메시지&타이포그래피 캠페인인
                ‘RIAction! 리액션 캠페인’을 전개한다. 2024년 10월 9일 한글날부터
                롯데리아 폰트 사이트(lotteriafont.com)를 거점으로 리아체를
                활용해 롯데리아의 꿈을 이야기하고 현실로 연결되는 리액션이 터져
                나오기를 기원할 예정. 이 공간에서는 세계인이 사랑하는 음식으로
                자리매김한 K-버거의 미래를 그려볼 수 있으며 K-버거에 대한 무한한
                응원 역시 전달할 수 있다.
              </p>
            </div>
            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_03.png"
                  alt="리아산스 이미지 3"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_03_m.png"
                  alt="리아산스 이미지 3"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End CampaignSection -->

        <!-- CommentsSection -->
        <section class="layout__wrap comments">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Comments</p>
              <p class="title">
                “단순한 음식이 아니다, <br class="mo-only" />
                이 정도면 예술의 경지다” <br />
                리아체로 더 리아답게, <br class="mo-only" />
                나만의 리아 액션을 시작하세요!
              </p>
            </div>

            <div class="riasans-detail__wrap">
              <div class="riasans-detail__star">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
              </div>
              <div class="riasans-detail__txt">
                <p>지금까지 단 한 번도 없었던 개념</p>
                <p>
                  앞으로는 롯데리아의 햄버거에 담긴 의도와
                  <br />철학을 설명하는 큐레이터가 필요할 것
                </p>
                <p>
                  대한민국 예비역이라면 <br class="mo-only" />
                  누구나 한 번쯤 <br />
                  군대리아에 대한 향수에 잠긴다
                </p>
                <p>띵작 중의 띵작 라이스버거</p>
                <p>솔직히 롯데리아의 메인 디시는 양념감자다</p>
                <p>미국인도 놀라는 환상의 시즈닝</p>
                <p>패티의 고정관념을 깨고, <br />번의 한계를 넘어 세계로</p>
              </div>
              <div class="riasans-detail__logo">
                <p class="logo-lotteria"><span class="blind">LOTTERIA</span></p>
                <p class="logo-lottegrs">
                  <span class="blind">LOTTE GRS</span>
                </p>
                <!-- <p class="logo-lotte"><span class="blind">LOTTE</span></p> -->
              </div>
            </div>
          </div>
        </section>
        <!-- End CommentsSection -->

        <!-- EssentialSection -->
        <section class="layout__wrap essential">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Essential</p>
              <p class="title">
                집현전 학자들도 궁금한 한글 폰트, <br />
                리아체의 야심찬 꿈 그리고 K-버거 전도사로서의 역량
              </p>
            </div>
            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_04.png"
                  alt="리아산스 이미지 4"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_04_m.png"
                  alt="리아산스 이미지 4"
                />
              </div>
            </div>
          </div>

          <div class="swiper-container swiper-riasans">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img
                  src="../assets/images/contents/img_riasans_05.png"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/contents/img_riasans_06.png"
                  alt=""
                />
              </div>
              <div class="swiper-slide">
                <img
                  src="../assets/images/contents/img_riasans_08.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End EssentialSection -->

        <!-- OpinionSection -->
        <section class="layout__wrap opinion">
          <div class="layout__inner">
            <div class="title-wrap">
              <p class="sub-title">Opinion</p>
              <p class="title">
                K-버거의 글로벌 역사, <br class="mo-only" />
                리아체로 쓴다!
                <span
                  >햄버거의 본고장 미국 진출 준비하는 롯데리아,
                  <br class="mo-only" />브랜딩의 핵심은 한글 폰트</span
                >
              </p>
            </div>
            <div class="desc-wrap">
              <p class="desc">
                2024년 현재 이른바 K-푸드가 세계를 강타하고 있다. 미국 식음
                트렌드 컨설팅 업체 에이에프앤드코(Af&co)는 '2024 식음료 트렌드'
                10가지 중 가장 먼저 ‘한식’을 언급했고, 네덜란드 식품 산업 컨설팅
                업체 '푸드바이디자인’ 역시 2024년 주목해야 할 트렌드로 한식을
                꼽았다. 과거 불고기, 비빔밥, 김치의 영역에 머물렀던 K-푸드는
                최근 영화 &lt;기생충&gt;으로 이름을 알린 ‘짜파구리’, 드라마
                &lt;오징어게임&gt;으로 급부상한 ‘달고나’, 연예인들이 사랑하는
                음식으로 자주 언급되어 유명세를 탄 떡볶이 등 간편식, 추억의
                간식, 분식 등으로 그 영역을 점차 확장해 나갔다. 지난해 틱톡을
                비롯한 SNS에 ‘김밥’ 관련 영상만 무려 13억 개가 업로드되었을
                정도.
                <br />
                <br />
                이처럼 지금까지의 K-푸드는 김치, 라면, 김밥 등 ‘한국에서 시작된
                음식’ 특유의 개성과 고유성, 무엇보다 신선함으로 세계인에게
                승부수를 던졌다. 그러나 한발 앞서 과감히 그다음 단계를 준비하는
                브랜드가 있었으니, 바로 ‘오리지널리티’의 선을 넘어 본토 정복을
                계획하는 롯데리아다. 롯데리아는 대한민국 토종 버거 브랜드로서
                K-버거의 자존심을 걸고 2025년 미국 LA에 1호점을 오픈할 계획이다.
                ‘햄버거의 본고장’ 미국에서 ‘K-버거’라는 새로운 장르를 당당히
                개척하고자 하는 야심이다.
              </p>
            </div>
            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_12.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_13.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_14.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_12_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_13_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_14_m.png"
                  alt="리아산스 이미지"
                />
              </div>
            </div>
            <div class="desc-wrap">
              <p class="desc">
                이 원대한 꿈을 실현하기 위해, 롯데GRS는 ‘세계가 K-버거의 세계로,
                KOREA GO RIA’라는 슬로건을 앞세워 미국 현지화를 위한 브랜딩을
                추진하고 있다. 통새우크런KIM버거, 불고기포텐버거,
                전주비빔라이스버거, 오징어얼라이브버거 등 한국적인 맛을 살린
                K-버거 신제품 라인업을 개발하며 브랜드의 코어인 메뉴의 개성을
                강화하는 한편, 한국산 햄버거 브랜드로서 롯데리아의 정체성을 가장
                잘 설명해 줄 한글 폰트 ‘리아체’를 론칭한다.
                <br />
                <br />
                롯데리아의 세 번째 폰트인 리아체는 2023년,
                촵딱체(촵땡겨체/딱붙어체)로 20만 명의 MZ 세대를 사로잡은 저력이
                있는 롯데리아가 세계인의 마음에 롯데리아라는 이름을 각인시키기
                위한 강력한 브랜딩 전략의 일환으로 기획되었다. 토종 버거
                롯데리아의 한글 사랑을 세계 무대에서도 유감없이 펼치기 위해
                이번에도 역시, 지구상에서 가장 엉뚱한 타이포그래피 퍼포먼스를
                내는 크리에이티브 크루 엉뚱상상(tt.서울 소속)과 함께했다. 토종
                버거의 글로벌 진출을 위한 브랜드 커뮤니케이션의 핵심 무기,
                리아체는 세계인의 관점에서 한글 조형의 매력과 아름다움을 충분히
                느낄 수 있는 정직하고 정제된 조형을 특징으로 한다.
                <br />
                <br />
                특히 ‘리아 리을(ㄹ)’이라는 시그니처 조형에 리아 리얼(Real) ‘토종
                버거’에 진심인 롯데리아가 K-버거라는 장르를 개척해 온 브랜드
                스토리, 리아 글로벌(Global) K-버거를 무기로 미국 본토 진출을
                준비해 온 담대한 철학, 리아 리멤버(Remember) 세계인의 가슴에
                ‘롯데리아’라는 이름과 K-버거의 맛을 각인시키기 위한 고군분투의
                과정, 세 가지 브랜드 스피릿을 담아내고자 했다. K-버거, 토종
                버거라는 브랜드 헤리티지와 가장 한국다운 목소리인 한글 폰트의
                만남. K-버거의 글로벌 역사를 써 내려갈 리아체Extra Bold는 2024년
                10월 9일 한글날, 롯데리아 폰트 사이트를 통해 론칭된다. 이어서
                리아체Bold, 리아체Regular 역시 2025년 2월 출시되며 KOREA GO RIA
                캠페인에 박차를 가할 예정이다.
              </p>
            </div>
            <div class="img-wrap">
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_15.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_16.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_17.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_18.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_19.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding pc-only">
                <img
                  src="../assets/images/contents/img_riasans_20.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_15_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_16_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_17_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_18_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_19_m.png"
                  alt="리아산스 이미지"
                />
              </div>
              <div class="no-padding mo-only">
                <img
                  src="../assets/images/contents/img_riasans_20_m.png"
                  alt="리아산스 이미지"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End OpinionSection -->

        <DownloadSection ref="downloadSectionRef" />
        <FooterComponent />
      </div>
      <!-- //e:scroll__inner -->
    </div>
    <!-- //e:scroll__wrap -->
  </div>
</template>

<style scoped lang="scss">
@import url("../assets/css/common.css");
@import url("../assets/css/swiper.css");
@import url("../assets/css/layout.css");
@import url("../assets/css/ui.css");

p {
  word-break: keep-all;
}
</style>
