<script setup>
import { onMounted } from "vue";
import loadScripts from "@/utils/loadScripts";

onMounted(() => {
  loadScripts();
});
</script>

<template>
  <div id="wrap">
    <!-- s:header -->
    <header class="header__wrap">
      <div class="header__inner">
        <div class="header__logo">
          <h1 class="logo-lotteria bl">
            <a href="/"><span class="blind">LOTTERIA</span></a>
          </h1>
        </div>
        <div class="header__insta">
          <a href="https://www.instagram.com/lotteria_kr/">
            <span class="blind">인스타그램</span>
          </a>
        </div>
      </div>
    </header>
    <!-- //e:header -->

    <div class="main__wrap">
      <div class="main__inner">
        <div class="main-btn main-btn__riasans">
          <router-link to="/riasans">
            RIA <br />
            SANS <br />
            리아체
          </router-link>
        </div>
        <div class="main-btn main-btn__chabddag">
          <router-link to="/chobddag">
            <p class="f-chab">CHOB</p>
            <p class="f-ddag">DDAG</p>
            <p>
              <span class="f-chab">촵 </span>
              <span class="f-ddag">딱 </span>
              <span class="f-chab">체</span>
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url("../assets/css/common.css");
@import url("../assets/css/swiper.css");
@import url("../assets/css/layout.css");
@import url("../assets/css/ui.css");

/* 추가적인 SCSS 스타일은 여기서 정의할 수 있습니다 */
</style>
