<template>
  <div class="fixed-image" @click="scrollToDownload">
    <img src="/fixed-download-icon.png" alt="Download" />
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["scrollToDownload"]);

const scrollToDownload = () => {
  emit("scrollToDownload");
};
</script>

<style scoped>
.fixed-image {
  position: fixed;
  top: 150px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
}

.fixed-image img {
  width: 150px;
}

@media (max-width: 768px) {
  .fixed-image {
    top: 50px; /* 모바일 화면에서의 top 위치 */
  }
  .fixed-image img {
    width: 76px;
  }
}
</style>
