<script setup></script>

<template>
  <header class="header__wrap">
    <div class="header__inner">
      <div class="header__logo">
        <h1 class="logo-lotteria bl">
          <a href="/"><span class="blind">LOTTERIA</span></a>
        </h1>
        <h2 class="logo-riasans">
          <a href="/riasans"><span class="blind">RIA SANS</span></a>
        </h2>
      </div>
      <div class="header__insta">
        <a href="https://www.instagram.com/lotteria_kr/">
          <span class="blind">인스타그램</span>
        </a>
      </div>
    </div>
    <div class="progress__bar">
      <div class="progress__inner"></div>
    </div>
  </header>
</template>

<style scoped lang="scss">
/* 추가적인 스타일을 정의할 수 있습니다. */
</style>
