<script setup></script>

<template>
  <footer class="footer__wrap">
    <div class="footer__inner">
      <div class="footer__logo">
        <div class="logo-lotteria bl">
          <a href="/"><span class="blind">LOTTERIA</span></a>
        </div>
      </div>
      <div class="footer__copy">
        COPYRIGHT © 2024 LOTTE GRS CO.,LTD ALL RIGHT RESERVED.
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* FooterComponent 관련 스타일 정의 */
</style>
